import React from "react";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

const LayoutContainer = styled.main`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function({ children }) {
  return (
    <LayoutContainer>
      <GlobalStyle />
      {children}
    </LayoutContainer>
  );
}
