import React from "react";
import Layout from "../styles/Layout";
import SEO from "../components/SEO";
import StyledTitle from "../styles/StyledTitle";

function IndexPage() {
  return (
    <Layout>
      <SEO />
      <StyledTitle>Coming Soon</StyledTitle>
    </Layout>
  );
}

export default IndexPage;
