import React from "react";
import Helmet from "react-helmet";

export default function() {
  return (
    <Helmet>
      <title>nikhilem.dev</title>
    </Helmet>
  );
}
